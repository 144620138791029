import { getBaseURL, getToken } from '../environment'
import { gcpToSydleOrder, sydleToGcpOrder } from '../order/OrderUtils'

const service = 'orders'
const baseURL = `${getBaseURL()}`

export const searchMillOrders = async () => {
    return await fetch(`${getBaseURL()}/${service}/boards/orders`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                data: data.map(pendingOrder => gcpToSydleOrder(pendingOrder)),
            }))
        )
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })
}

export const searchPaginatedOrders = async (from, size, filter) => {
    const { search, requestDate, deliveryDate, status, sellers } = filter
    const queryParams = new URLSearchParams({
        page: from,
        size,
    })
    if (search) queryParams.append('filter', search)
    if (requestDate)
        queryParams.append('requestDate', new Date(requestDate).toISOString())
    if (deliveryDate)
        queryParams.append('deliveryDate', new Date(deliveryDate).toISOString())
    if (status.length > 0) {
        status.forEach(status => queryParams.append('statusIds', status))
    }
    if (sellers.length > 0) {
        sellers.forEach(seller => queryParams.append('sellerIds', seller))
    }

    return await fetch(`${baseURL}/${service}/orders?${queryParams}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data, total }) => ({
                data: {
                    orders: data.map(pendingOrder =>
                        gcpToSydleOrder(pendingOrder)
                    ),
                    total: total,
                },
            }))
        )
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })
}
export const getIntegrationError = async orderId => {
    return await fetch(
        `${baseURL}/${service}/orders/integrationError/${orderId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        }
    )
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
export const reprocessIntegration = async orderId => {
    await fetch(
        `${baseURL}/${service}/orders/${orderId}/reprocessIntegration`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        }
    )
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

// TODO - unificar com getById
export const getOrder = async orderId => {
    const response = await fetch(`${baseURL}/${service}/orders/${orderId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return { data: gcpToSydleOrder(response.data) }
}

export const getOrderById = async orderId => {
    const response = await fetch(`${baseURL}/${service}/orders/${orderId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return { order: gcpToSydleOrder(response.data) }
}

export const updateOrderStatus = async (id, status) => {
    return fetch(`${baseURL}/${service}/orders/${id}/status`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ status }),
    })
        .then(() => ({ data: null }))
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const verifiesOrderCanBeCancelled = async orderId => {
    const response = await fetch(
        `${baseURL}/${service}/orders/${orderId}/checkCancel`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        }
    )
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return { data: response }
}

export const cancelOrder = async (orderId, cancellationReason) => {
    const response = await fetch(
        `${baseURL}/${service}/orders/${orderId}/cancel`,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({ cancellationReason }),
        }
    )
    if (response.status > 299 || response.status < 200)
        throw new Error(await response.text())

    return response.json()
}

export const saveEditedOrder = async (orderId, sydleOrder) => {
    const order = sydleToGcpOrder(sydleOrder)
    const response = await fetch(`${baseURL}/${service}/orders/${orderId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(order),
    })

    if (response.status > 299 || response.status < 200)
        throw new Error(await response.text())

    return response.json()
}

export const setIsUseFragmentOrder = async (orderId, isUseFragmentOrder) => {
    const response = await fetch(
        `${baseURL}/${service}/ordersWithFragments/${orderId}`,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({ isUseFragmentOrder }),
        }
    )

    if (response.status > 299 || response.status < 200)
        throw new Error(await response.text())

    return response.json()
}

export const getOrdersWithFragments = async () => {
    const response = await fetch(
        `${baseURL}/${service}/boards/ordersWithFragments`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        }
    )
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })

    return {
        orders: response.data.map(order => gcpToSydleOrder(order)),
    }
}

export const createSimpleBilling = async sydleOrder => {
    const order = sydleToGcpOrder(sydleOrder)
    const response = await fetch(`${baseURL}/${service}/simpleBillings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(order),
    })

    if (response.status > 299 || response.status < 200)
        throw new Error(await response.text())

    return response.json()
}

export const searchSimpleBillings = async ({ from, size, filter, view }) => {
    const queryParams = new URLSearchParams({
        page: from,
        size,
    })

    if (filter) queryParams.append('filter', filter)

    let endpoint = 'simpleBillings'
    if (view) endpoint = 'boards/simpleBillings'

    const response = await fetch(
        `${baseURL}/${service}/${endpoint}?${queryParams}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        }
    )
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return {
        orders: response.data.map(pendingOrder =>
            gcpToSydleOrder(pendingOrder)
        ),
        total: response.total,
    }
}
