import React from 'react'
import styled from 'styled-components'
import { IoMdSync } from 'react-icons/io'
import { GoAlert } from 'react-icons/go'
import Colors from '../../commons/Colors'
import RoundButton from '../../commons/components/RoundButton'

const IntegrationErrorComponent = ({
    orderId,
    integrationError,
    onSyncClick,
    isSyncLoading,
}) => {
    const [hasSyncronized, setHasSyncronized] = React.useState(false)

    if (!integrationError) return
    return (
        <ErrorContentContainer>
            <ErrorTextContainer>
                <div style={{ display: 'flex' }}>
                    <AlertIcon size={18} />

                    <ErrorTitle style={{ color: Colors.white }}>
                        ATENÇÂO
                    </ErrorTitle>
                </div>
                <ErrorText style={{ color: Colors.white }}>
                    Seu pedido não foi integrado. Deseja reenviá-lo?
                </ErrorText>
                <ErrorText>Erro: {integrationError}</ErrorText>
            </ErrorTextContainer>
            <div
                style={{
                    alignSelf: 'center',
                    paddingTop: 2.5,
                }}
            >
                <SyncButton
                    onClick={() => {
                        !hasSyncronized && onSyncClick(orderId)
                        setHasSyncronized(true)
                    }}
                    disabled={hasSyncronized}
                    loading={isSyncLoading}
                >
                    <SyncIcon size={22} />
                    Sincronizar
                </SyncButton>
            </div>
        </ErrorContentContainer>
    )
}

const SyncIcon = styled(IoMdSync)`
    display: flex;
    align-self: center;
`
const AlertIcon = styled(GoAlert)`
    display: flex;
    align-self: center;
    color: ${Colors.white};
`

const SyncButton = styled(RoundButton)`
    display: flex;
    align-items: center;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    line-height: 1.71;
    width: fit-content;
    align-self: center;
    gap: 10px;
`

const ErrorContentContainer = styled.div`
    max-height: 30vh;
    padding: 12px;
    height: auto;
    background-color: ${Colors.darkRedTwo};
    display: flex;
    flex-direction: column;
    border-radius: 5px;
`
const ErrorTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 5px;
    padding-bottom: 5px;
`
const ErrorText = styled.span`
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.white};
    margin-left: 30px;
`
const ErrorTitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: ${Colors.white};
    margin-left: 12px;
`

export default IntegrationErrorComponent
