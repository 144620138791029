import React from 'react'

const SyncErrorIcon = () => {
    return (
        <svg
            width="81"
            height="81"
            viewBox="0 0 81 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.125 40.5c0 7.459 3.071 14.175 7.965 19.035l-5.096 5.096c-1.046 1.046-.304 2.869 1.215 2.869h14.479c.945 0 1.687-.743 1.687-1.688V51.334c0-1.519-1.823-2.262-2.869-1.181l-4.691 4.69c-3.645-3.712-5.94-8.74-5.94-14.343 0-8.066 4.725-15.052 11.576-18.293 1.148-.54 1.924-1.586 1.924-2.835v-.64c0-2.296-2.396-3.747-4.455-2.768-9.315 4.252-15.795 13.635-15.795 24.536zm27 16.875h6.75v-6.75h-6.75v6.75zM66.791 13.5H52.313c-.946 0-1.688.742-1.688 1.688v14.478c0 1.519 1.822 2.261 2.869 1.181l4.691-4.69c3.645 3.712 5.94 8.74 5.94 14.343 0 8.066-4.725 15.053-11.576 18.292-1.148.54-1.924 1.587-1.924 2.835v.608c0 2.295 2.396 3.746 4.455 2.767 9.315-4.218 15.795-13.6 15.795-24.502 0-7.459-3.071-14.175-7.965-19.035l5.096-5.096c1.046-1.046.304-2.869-1.215-2.869zM40.5 43.875a3.385 3.385 0 0 0 3.375-3.375V27a3.385 3.385 0 0 0-3.375-3.375A3.385 3.385 0 0 0 37.125 27v13.5a3.385 3.385 0 0 0 3.375 3.375z"
                fill="#9D2835"
                opacity=".3"
            />
        </svg>
    )
}

export default SyncErrorIcon
